import axios from "axios";
import Tooltip from '@material-ui/core/Tooltip';
import moment from "moment-timezone";
import iMoment from "moment-hijri";
import { saveAs } from "file-saver";
import printJS from "print-js";
import store from "../store";
import { showSnackbarAction } from "../store/Snackbar/actions";
import { loginReceive, deviceType } from "../store/Auth/actions";
import { addNavbarBreadcrumb } from "../store/Breadcrumbs/actions";
import History from "../routes/History";
import messages from "../assets/locals/messages";
import ActiveIcon from "../assets/images/icons/active.svg";
import InactiveIcon from "../assets/images/icons/in-active.svg";
import SuspendedIcon from "../assets/images/icons/suspended.svg";
import ExpiredIcon from "../assets/images/icons/expired.svg";
import {
  arabicRegex,
  userStatus,
  expire__free_trial_msg,
  allPermissions,
} from "./Constants";
import AnchorElTooltips from "../components/Controls/Tooltip/tooltip";
import { DownBlueArrow, FileAvatar, leftArrowIcon } from "./Images";
import { DownloadIcon } from "../assets/images/icons/caseTasks";
import { importExcelTemplateResponse } from "../store/Lookups/actions";

import dayjs from 'dayjs';  
import utc from 'dayjs/plugin/utc'; // Import the utc plugin  
import timezone from 'dayjs/plugin/timezone'; // Import the timezone plugin  
// Extend Day.js with the plugins  
dayjs.extend(utc);  
dayjs.extend(timezone);  

var Tafgeet = require("tafgeetjs"); //used in convertNumbersToWordsWzCurrency func

/** @module shared */
/**
 * @function changePlusToPercentage2B
 * @description  -change + to %2B before send request bec it translated to space in back-end when it was a param
 * @param {String} string string to be changed
 * @returns string replace + with %2B
 */
export const changePlusToPercentage2B = (string) => {
  return string.replace("+", "%2B");
};

/**
 * @function dispatchSnackbarError
 * @description show error msg snackbar
 * @param {Array|String} data array of errors or string error
 * @returns  snackbar with error message
 */
export function dispatchSnackbarError(data) {
  if (data) {
    if (data?.errors) {
      const errorMsg = Object.values(data.errors)[0];
      store.dispatch(showSnackbarAction(errorMsg, "error"));
    } else {
      if (data !== expire__free_trial_msg)
        store.dispatch(
          showSnackbarAction(data.message ? data.message : data, "error")
        );
    }
  }
}

/**
 * @function dispatchSnackbarSuccess
 * @description show success msg snackbar
 * @param {String} message string message
 * @returns  snackbar with success message
 */
export function dispatchSnackbarSuccess(
  message,
  action,
  handleActionClick,
  actionStyle
) {
  const lang = store.getState().lang;
  store.dispatch(
    showSnackbarAction(
      messages[lang].snackbar[message.msg || message].concat(
        message.value ? message.value : ""
      ),
      "success",
      action,
      handleActionClick,
      actionStyle
    )
  );
}

/**
 * @function getSubDomain
 * @description check url if it contain subdomain get it (length of subdomain is more than 3)
 * @returns {String} subdomain if found
 */
export const getSubDomain = () => {
  let host = window.location.host;
  if (host.startsWith("www.")) {
    host = host.slice(4); // remove www. from hostname
  }
  const hostnameParts = host.split(".");
  // No subdomain
  if (hostnameParts.length <= 3) {
    return "";
  } else {
    return hostnameParts[0];
  }
};

/**
 * @function generateBreadcrumbs
 * @description generate breadcrumbs by splitting url
 * 1- create route for each breadcrumb called href
 * 2-first item is always empty string,so didn't add it to []
 * 3-sometime labelArrays contain nos ex. invoices/show/10 ..10 is a no and it didn't has a rout so skip it
 * 4-skip home
 * 5-skip single breadcrumb
 * 6-navbar breadcrumb array if it contains many items then take last one if it was 1 then take it (it was a main category)
 * @returns {Array} array of breadcrumbs
 */
export const generateBreadcrumbs = () => {
  const lang = store.getState().lang;
  // Take only the first two parts of the url (first element is empty string for home page)
  const labelsArray = History.location.pathname.split("/");
  const breadCrumbs = [];
  let hrefString = "/";
  const labelsArraysRealLength = labelsArray.filter((x) => x).length;
  const labelsArrayRealValues = labelsArray.filter((x) => x);

  labelsArray.forEach((label, index) => {
    hrefString += index === 0 ? "" : `${label}/`;
    // not valid breadcrumb will return empty array or obj
    return (!label && index === 1) || //empty
      !isNaN(label) || // not a number ex. no 2 in  invoices/show/2
      hrefString == "/" || // don't return home
      labelsArraysRealLength == 1 // single breadcrumb title ex. invoices only
      ? {}
      : breadCrumbs.push({
        label: label
          ? messages[lang].navbar[label]
            ? messages[lang].navbar[label] // key name in navbar object in localization file should be the same as route
            : messages[lang].navbar[ //if label didn't exist concat with last label(second level of breadcrumb)
            label +
            labelsArray[index - 1]?.charAt(0)?.toUpperCase() +
            labelsArray[index - 1]?.slice(1)
            ]
          : "",
        active:
          //make it active if it wasn't last item/label
          index !== labelsArray.length - labelsArraysRealLength
            ? true
            : false,
        href: hrefString,
      });
  });
  // add navbar breadcrumb/ title
  if (breadCrumbs.length) {
    //take last item in breadcrumbs
    store.dispatch(
      addNavbarBreadcrumb(breadCrumbs[breadCrumbs.length - 1].label) //already translated
    );
  } //else it was main category take 1st ite,
  else if (labelsArraysRealLength == 1) {
    store.dispatch(
      addNavbarBreadcrumb(messages[lang].navbar[labelsArrayRealValues[0]])
    );
    //home
  } else if (labelsArraysRealLength == 0 && breadCrumbs.length == 0) {
    store.dispatch(addNavbarBreadcrumb(messages[lang].navbar["dashboard"]));
  }
  return breadCrumbs;
};

/**
 * @function splitObjectStringKeys 
 * @description Returns the wanted path as a variable not string
Example: if we have object {company: {logo: "logo"}} and a path "company.logo" 
it will return the value of company.logo variable
 * @param {Object} object object
 * @param {String} path path
 * @description Takes an object and a string path for nested keys in the object
 * @param {String} defaultValue defaultValue
 * @returns  the wanted path as a variable not string
 */
export const splitObjectStringKeys = (object, path, defaultValue = "") => {
  return path.split(".").reduce((o, p) => (o ? o[p] : defaultValue), object);
};

/**
 * @function isMobileDevice
 * @description check if opened device is Mobile
 * @returns {boolean} with true/false
 */
export const isMobileDevice = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone|android/i.test(userAgent)) {
    store.dispatch(deviceType("android"));
    return true;
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    store.dispatch(deviceType("ios"));
    return true;
  }
  store.dispatch(deviceType("web"));
  return false;
};

/**
 * @function generateDynamicLink
 * @param {String} token token
 * @description get token and generate a dynamic link with every account activation
 * @returns  -deep linking with mobile app
 */
export const generateDynamicLink = async (token) => {
  let payload = {
    dynamicLinkInfo: {
      domainUriPrefix: process.env.REACT_APP_MOBILE_DYNAMIC_LINK,
      link: `${isLocal() ? "http" : "https"}://${process.env.REACT_APP_BASE_URL
        }?token=${token}`,
      androidInfo: {
        androidPackageName: process.env.REACT_APP_ANDROID_PACKAGE_NAME,
      },
      iosInfo: {
        iosBundleId: process.env.REACT_APP_IOS_BUNDLE_ID,
      },
    },
  };

  try {
    const res = await axios.post(
      `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyCIvea1Ea25qBtqWfyzrmVFX2d3lU2F6E0`,
      payload
    );

    if (res.data?.shortLink) {
      return res.data.shortLink;
    } else {
      return false;
    }
  } catch (err) {
    throw err;
  }
};

/**
 * @function isLocal
 * @description check it was localhost / live
 * @returns  -return boolean to check if it was local server
 */
export const isLocal = () => {
  return process.env.REACT_APP_BASE_URL.includes("localhost");
};

/**
 * @function getInitials
 * @description if name is Alaa Moustapha it will return AM
 * @param {String} name name
 * @returns  -return first 2 letters in full name
 */
export const getInitials = (name) => {
  if (name) {
    const parts = name.split(" ");

    // Return only 2 letters
    return parts
      .map((part, ind) => {
        const firstLetter = part.substring(0, 1);
        // Arabic names only show first letter now
        if (arabicRegex.test(firstLetter) && ind === 0) {
          return firstLetter + " ";
        }

        return firstLetter;
      })
      .join("")
      .substring(0, 2); // Only two letters including space in arabic letters
  }

  return "";
};

/**
 * @function appendLoader
 * @param {Object} menuRef menu reference
 * @param {String} position menu position
 * @description append loader to menu on scroll (waiting for data)
 * @returns  - add loader to menu
 */
export const appendLoader = (menuRef, position) => {
  const lang = store.getState().lang;
  let loadingEl = document.querySelector("#loadingEl");
  if (!loadingEl) {
    loadingEl = document.createElement("label");
    loadingEl.id = "loadingEl";
  }
  loadingEl.classList.add("loadingText");
  loadingEl.textContent = `${messages[lang].actions.loading}...`;
  if (menuRef.current) {
    if (document.querySelector("#loadingEl")) {
      menuRef.current.replaceChild(
        loadingEl,
        menuRef.current.childNodes[menuRef.current.childNodes.length - 1]
      );
    } else {
      // Position top is removed now (Some crashing bugs on appending at start and typing fast)
      position === "top"
        ? menuRef.current.insertBefore(loadingEl, menuRef.current.childNodes[0])
        : menuRef.current.appendChild(
          loadingEl,
          menuRef.current.childNodes[menuRef.current.childNodes.length - 1]
        );
    }
  }
};

/**
 * @function removeLoader
 * @param {Object} menuRef menu reference
 * @description remove loader from menu
 * @returns  - remove loader from menu
 */
export const removeLoader = (menuRef) => {
  let loadingEl = document.querySelector("#loadingEl");
  if (loadingEl && menuRef.current && menuRef.current.contains(loadingEl)) {
    menuRef.current.removeChild(loadingEl);
  }
};

/**
 * @function decideStatusCardProps
 * @param {status} string user status
 * @description check user card depend on his status
 * @returns {Object} - user object status data
 */
export const decideStatusCardProps = (status) => {
  const { lang } = store.getState();
  switch (status) {
    case userStatus.ACTIVE:
      return {
        statusKey: messages[lang].users.active,
        statusIcon: ActiveIcon,
        statusBgColorKey: "lightSeaGreen",
        statusColorKey: "seaGreen2",
      };
    case userStatus.INACTIVE:
      return {
        statusKey: messages[lang].users.inactive,
        statusIcon: InactiveIcon,
        statusBgColorKey: "lightPurple",
        statusColorKey: "purple",
      };
    case userStatus.SUSPENDED:
      return {
        statusKey: messages[lang].users.suspended,
        statusIcon: SuspendedIcon,
        statusBgColorKey: "lightRed",
        statusColorKey: "red",
      };
    case userStatus.EXPIRED:
      return {
        statusKey: messages[lang].users.expired,
        statusIcon: ExpiredIcon,
        statusBgColorKey: "lightGrey",
        statusColorKey: "grey",
      };
    default:
      return {};
  }
};

/**
 * @function addOrSubtractInterval
 * @param {date} reminder  - reminder date
 * @param {date} startDate  - start date
 * @returns {date} - date difference between reminder and start date
 */
export const addOrSubtractInterval = (reminder, startDate) => {
  return (
    reminder &&
    startDate &&
    (reminder.condition === "before"
      ? moment(startDate).subtract(reminder.amount, reminder.unit)
      : moment(startDate).add(reminder.amount, reminder.unit))
  );
};

/**
 * @function removePersonalDataFromURL
 * @description remove personal data from url on navigation
 * @returns remove personal data from url while login
 */
export const removePersonalDataFromURL = (pathname) => {
  const queryParams = new URLSearchParams(History.location.search);
  const token = queryParams.get("token");
  const user = queryParams.get("user");
  if (token && user) {
    if (pathname !== "/") {
      //landing page route
      localStorage.setItem("userInfo", user);
      localStorage.setItem("token", token);
    }
    setCookie("userInfo", user);
    setCookie("token", token);
    store.dispatch(loginReceive(JSON.parse(user)));
    // store.dispatch(getFirmSettings({ option: "general" }));
    let path = History.location.pathname?.includes("blankPage")
      ? `/${History.location.pathname.split("/")[1]}`
      : History.location.pathname;
    History.replace({
      pathname: pathname || path,
      search: "",
    });
  }
};

/**
 * @function generateCasesListFilter
 * @description generate Cases List Filter
 * @param {Object} values  - form values
 * @returns {Object} -formatted values object
 */
export const generateCasesListFilter = (values) => {
  return {
    "filter[category]":
      values.category && values.category.length > 0
        ? values.category.map((item) => item.id)
        : [],
    "filter[court]":
      values.court && values.court.length > 0
        ? values.court.map((item) => item.id)
        : [],
    "filter[circle]":
      values.circle && values.circle.length > 0
        ? values.circle.map((item) => item.id)
        : [],
    "filter[type]":
      values.type && values.type.length > 0
        ? values.type.map((item) => item.id)
        : [],
    "filter[status]":
      values.status && values.status.length > 0
        ? values.status.map((item) => item.id)
        : [],
    "filter[members]":
      values.members && values.members.length > 0
        ? values.members.map((item) => item.id)
        : [],
    "filter[clients]":
      values.clients && values.clients.length > 0
        ? values.clients.map((item) => item.id)
        : [],
    "filter[created_between]": [
      values.start_date != null
        ? moment(values.start_date).locale("en").format("DD-MM-YYYY HH:mm:ss")
        : "null",
      values.end_date != null
        ? moment(values.end_date).locale("en").format("DD-MM-YYYY HH:mm:ss")
        : "null",
    ].join(","),
  };
};

/**
 * @function scrollToTopInScrollableDiv
 * @param {Object} ref  - reference
 * @description scroll to top of element
 * @returns scrolling to top of screen
 */
export const scrollToTopInScrollableDiv = (ref) => {
  if (ref?.current) {
    ref.current?.scrollTo(0, 0);
  }
};

/**
 * @function scrollToView
 * @param {Object} ref  - reference
 * @param {string} behavior  - behavior of scrolling
 * @description scroll to top of view with specific behavior
 * @returns scrolling with behavior
 */
export const scrollToView = (ref, behavior) => {
  if (ref?.current)
    ref.current.scrollIntoView({ behavior: behavior || "smooth" });
};

/**
 * @function getValuesOfObjectsOfArray
 * @param {Array.<Object>} arr  - array of objects (with key and value)
 * @description take an array of objs and return values of each object in array
 * @returns {Array} array of arrays (with objects values)
 */
export const getValuesOfObjectsOfArray = (arr) => {
  return arr.map((obj, i) => Object.values(obj));
};

/**
 * @function ApproximateNo
 * @param {number} num  - number to approximate
 * @param {number} decimals - number of digits to approximated
 * @description take number and no of decimals want to approximate and return this no approximated
 * @returns {number} approximated number
 */
export const approximateNo = (num, decimals) => {
  if (num) {
    return (
      Math.round(num * Math.pow(10, decimals) + 0.001) / Math.pow(10, decimals)
    ).toFixed(decimals);
  } else return 0;
};

/**
 * @function checkTimezone
 * @param {boolean} isHijriDate  - is date format is Hijri ?
 * @param {string} date - the date
 * @param {string} tz  - timezone
 * @param {string} format - date format
 * @description take 1(isHijriDate) prop which define is date is hijri or meladi and 2(date) and 3(timezone) and 4(date format) and return the new date formatted with it's timezone
 * @returns {string} date after applying Hijri/Georgian ,timezone and format
 */
export const checkTimezone = (isHijriDate, date, tz, format) => {
  return isHijriDate
    ? iMoment
      .utc(date)
      .tz(tz || "Africa/Cairo")
      .format(format)
    : dayjs
      .utc(date)
      .tz(tz || "Africa/Cairo")
      .format(format);
};
export const convertUTCTimeToLocalTime = (date, format, locale) => {
  const generalFirmSettings =
    store.getState().firmSettings?.generalFirmSettings;
  const tz =
    generalFirmSettings?.timezone ||
    JSON.parse(localStorage.getItem("generalFirmSettings"))?.timezone;
  if (date && tz) {
    return moment
      .utc(date)
      .tz(tz)
      .locale(locale || "en")
      .format(format || "LLLL");
  }
};
/**
 * @function DownloadTheFile
 * @param {string} url  - the file link
 * @param {string} name - the file name
 * @description download a file
 * @returns file downloaded
 */
export const downloadTheFile = (url, name) => {
  saveAs(url, name);
};

/**
 * @function updateObjectListToFitBEerrInYup
 * @param {string} action
 * @param {array} list
 * @param {number} i
 * @description take (action (add or remove) & validation list [{isErr: true, index:1}] & index of input ) and return updated List with back end errors in Yup
 * @returns list-array-
 */
export const updateObjectListToFitBEerrInYup = (action, list = [], i) => {
  // [{isErr: true, index:1}]
  if (action === "add") {
    if (!list?.find((ele) => ele.index == i)) {
      list.push({ isErr: true, index: i });
      return list;
    } else {
      let objIndex = list.findIndex((obj) => obj.index == i);
      list[objIndex].isErr = true;
      return list;
    }
  } else if (action === "remove") {
    if (list?.find((ele) => ele.index == i)) {
      let objIndex = list.findIndex((obj) => obj.index == i);
      list[objIndex].isErr = false;
      return list;
    } else {
      list.push({ isErr: false, index: i });
      return list;
    }
  } else {
    return list;
  }
};

/**
 * @function convertNumbersToWordsWzCurrency
 * @param {number} value
 * @description convert numbers to words with currency
 * @returns numInWords-string-
 */
export const convertNumbersToWordsWzCurrency = (value) => {
  let numInWords = "";
  if (value) {
    numInWords = new Tafgeet(value, "SAR").parse();
  }
  return numInWords;
};

export const print = (url, type) => {
  if (url) {
    printJS({ printable: url, type: type || "pdf" });
  }
};

export const handleCustomLabel = (tag, list) => {
  const lang = store.getState().lang;
  let customTagList;
  if (list.length) {
    customTagList = list.map((item, i) => {
      if (tag.id !== "new_client") {
        let itemUpdated = item?.includes(".") ? item?.split(".") : item;
        let condition =
          typeof itemUpdated == "string"
            ? tag[itemUpdated]
            : tag[itemUpdated[0]]?.[itemUpdated[1]];
        if (condition) {
          if (tag[item] == "company") {
            return messages[lang].invoices.company;
          } else if (tag[item] == "person") {
            return messages[lang].invoices.member;
          } else {
            return condition;
          }
        } else return " ";
      } else {
        return " ";
      }
    });
  }
  return customTagList;
};

export const handleMoreItem = (list) => {
  if (list?.length > 1) {
    let restItem = `+${list.length - 1}`;
    return <span className="more"> {restItem} </span>;
  } else {
    return " ";
  }
};

export const removeDuplicateFromList = (list) => {
  let uniqueList;
  if (list?.length) {
    uniqueList = list.filter((obj, index) => {
      return index === list.findIndex((o) => obj.id === o.id);
    });
    return uniqueList;
  } else {
    return list;
  }
};

export const addUniqueItemsToList = (list) => {
  let lastItem = list?.[list?.length - 1];
  if (list?.length > 0) {
    list.splice(list?.length - 1, 1);
    if (list?.find((ele) => ele?.id == lastItem?.id)) {
      list.splice(
        list?.findIndex((ele) => ele?.id == lastItem?.id),
        1
      );
      return list;
    } else {
      list.push(lastItem);
      return list;
    }
  } else {
    return list;
  }
};

export const checkEmptyPhones = (AllPhonesValues) => {
  return AllPhonesValues.filter((phoneNumber) => {
    if (phoneNumber["phone"].length > 3) {
      return true;
    } else {
      return false;
    }
  }).map((phoneNumber) => ({
    phone: phoneNumber.phone.replace(phoneNumber.country.call_key, ""),
    code: phoneNumber.country.call_key,
  }));
};

/**
 * @function checkEmptyAddress
 * @description  -check if input address is empty
 * @param {Array.<object>} AllAddressesValues array of Addresses
 * @returns  {array} array of filled addresses
 */
export const checkEmptyAddress = (AllAddressesValues) => {
  return AllAddressesValues.map((address) => {
    if (Object.keys(address)) {
      return {
        country_id: address.country_id?.id || null,
        city: address.city?.name || null,
        address: address.address,
      };
    }
  });
};

/**
 * @function checkEmptyEmails
 * @description  -check if input email is empty
 * @param {Array.<object>} AllEmailsValues array of emails
 * @returns  {array} array of filled emails
 */
export const checkEmptyEmails = (AllEmailsValues) => {
  return AllEmailsValues.filter((email) => email?.email);
};

export const handleTrim = (value, trimedIndex) => {
  if (value && value?.length > trimedIndex) {
    return value.slice(0, trimedIndex) + "...";
  } else {
    return value;
  }
};

export const handleTooltip = (
  tooltipMsg,
  tooltipContent,
  indexToViewTooltip
) => {
  if (tooltipMsg && tooltipMsg?.length > indexToViewTooltip) {
    return (
      <AnchorElTooltips msg={tooltipMsg}>{tooltipContent}</AnchorElTooltips>
    );
  } else {
    return tooltipContent;
  }
};

export const handleSeeMoreView = (list, action) => {
  const lang = store.getState().lang;
  return (
    <>
      {list?.pagination &&
        list?.pagination?.total_pages > 1 &&
        list?.pagination?.current_page < list?.pagination?.total_pages ? (
        <div
          className="d-flex justify-content-end cursor-pointer"
          onClick={action}
        >
          {" "}
          <img src={DownBlueArrow} alt="down arrow" className="me-2" />
          <span className="blue">{messages[lang].seeMore}</span>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

// export const handleDateFormat = (date, format) => {
//   const isHijriDate = store.getState().auth?.isHijriDate;
//   const generalFirmSettings =
//     store.getState().firmSettings?.generalFirmSettings;
//   const generalFirmSettingsLocalStorage = JSON.parse(
//     localStorage.getItem("generalFirmSettings")
//   );
//   let dateFormated = date
//     ? checkTimezone(
//       isHijriDate,
//       isHijriDate ? iMoment(date) : dayjs(date),
//       generalFirmSettings?.timezone ||
//       generalFirmSettingsLocalStorage?.timezone,
//       isHijriDate
//         ? `iYYYY/iMM/iDD  ${format == "dateTime" ? "hh:mm a" : ""}`
//         : `YYYY/MM/DD  ${format == "dateTime" ? "hh:mm a" : ""}`
//     )?.split(" ")
//     : null;
//   return (
//     <>
//       <span>{dateFormated?.[0]}</span>
//       {format == "dateTime" && (
//         <span className="paddingR">
//           {dateFormated?.[2]} {dateFormated?.[3]}
//         </span>
//       )}
//     </>
//   );
// };

// export const handleTimeFormat = (date) => {
//   const isHijriDate = store.getState().auth?.isHijriDate;
//   const generalFirmSettings =
//     store.getState().firmSettings?.generalFirmSettings;
//   const generalFirmSettingsLocalStorage = JSON.parse(
//     localStorage.getItem("generalFirmSettings")
//   );
//   let dateFormated = date
//     ? checkTimezone(
//       isHijriDate,
//       isHijriDate ? iMoment(date) : moment(date),
//       generalFirmSettings?.timezone ||
//       generalFirmSettingsLocalStorage?.timezone,
//       "hh:mm a"
//     )
//     : null;
//   return dateFormated;
// };

export const isEndDateBigger = (startDate, endDate) => {
  if (startDate && endDate) {
    let start = new Date(startDate).toISOString().split("T")[0];
    let end = new Date(endDate).toISOString().split("T")[0];
    if (end > start) {
      return true;
    } else if (end < start) {
      return false;
    }
    return true;
  }
  return true;
};

export const isEndTimeBigger = (startTime, endTime) => {
  if (startTime && endTime) {
    let start = new Date(startTime).toISOString().split("T")[1];
    let end = new Date(endTime).toISOString().split("T")[1];
    if (end > start) {
      return true;
    } else if (end < start) {
      return false;
    }
    return true;
  }
  return true;
};

export const setCookie = (cName, cValue, expDays) => {
  let date = new Date();
  date.setTime(date.getTime() + 1 * 24 * 60 * 60 * 1000);
  const expires = "expires=" + date.toUTCString();
  document.cookie = `${cName}=${cValue}; ${expires}; path=/`;
  // domain=${process.env.REACT_APP_BASE_URL}
};
//local host set :oracle-dev-app.mustand.localhost

export const getCookie = (name) => {
  var dc = document.cookie;
  var prefix = name + "=";
  var begin = dc.indexOf("; " + prefix);
  if (begin == -1) {
    begin = dc.indexOf(prefix);
    if (begin != 0) return null;
  } else {
    begin += 2;
    var end = document.cookie.indexOf(";", begin);
    if (end == -1) {
      end = dc.length;
    }
  }
  // because unescape has been deprecated, replaced with decodeURI
  //return unescape(dc.substring(begin + prefix.length, end));
  return decodeURI(dc.substring(begin + prefix.length, end));
};

export const removeCookie = (cName) => {
  document.cookie = `${cName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
  // domain=${process.env.REACT_APP_BASE_URL};
};
//local host set :oracle-dev-app.mustand.localhost

export const handleDateTimePayload = (date, time) => {
  if (date && time) {
    let newDate = new Date(date).toISOString().split("T")[0];
    let newTime = new Date(time).toString().split(" ")?.[4];
    let dateFormated = newDate?.split("-")?.reverse()?.join("-");
    return `${dateFormated} ${newTime}`;
  }
  return null;
};

export const isFirstDateGreatOrEqualSeconde = (firstDate, secondeDate) => {
  let date1 = new Date(firstDate);
  let date2 = new Date(secondeDate);
  if (date1 < date2) {
    return false;
  } else if (date1 > date2) {
    return true;
  } else {
    return true;
  }
};

export const findObjInArrayByKey = (arr, key, value, defaultValue) => {
  return arr?.find((ele) => ele[key] == value) || defaultValue || "";
};

export const setValueOrDefaultValue = (value, defaultValue) => {
  return value || defaultValue || "";
};

export const renderFiles = (files, noOfFilesToShow, isSeeMore, href) => {
  const lang = store.getState().lang;
  return (
    <div className="dropzoneContainer ">
      <div className="previewContainer mt-3">
        {files && files.length ? (
          files.slice(0, noOfFilesToShow || files.length).map((file, index) => {
            return (
              <div className="previewCard m-2" key={index}>
                <div className="iconContainer">
                  <img src={FileAvatar} alt="" />
                </div>
                <div className="d-flex flex-column mx-2 flex-grow-1">
                  <Tooltip title={file.name}>
                    <span className="clipped fileName f_size_12_pt ">
                      {file.name}
                    </span>
                  </Tooltip>
                </div>
                <div
                  className="closeIcon downloadICon"
                  onClick={() => downloadTheFile(file.full_path, file.name)}
                >
                  <DownloadIcon />
                </div>
              </div>
            );
          })
        ) : (
          <p>{messages[lang].tasks.noAttachments}</p>
        )}
      </div>
      {isSeeMore && files.length > noOfFilesToShow ? (
        <a href={href} className="ms-2">
          {messages[lang].seeMore}
        </a>
      ) : null}
    </div>
  );
};

/**
 * @function displayData
 * @description  -take data and return it with ui
 * @param {string} icon
 * @param {string} label
 * @param {string} content
 * @param {string} wrapperStyle
 * @returns UI
 */
export const displayData = (icon, label, content, wrapperStyle) => {
  return (
    <div className={`d-flex mb-3 ${wrapperStyle}`}>
      <div className="col-1 icon d-flex justify-content-center align-items-center view-icon-wrapper">
        {icon}
      </div>
      <div className={`mx-2 w-100`}>
        <p className="mb-0 dataTitle-wrapper">{label}</p>
        <p className={`mb-0 data-wrapper mt-2`}>{content || "-"}</p>
      </div>
    </div>
  );
};

export const handleDateTimeForReminder = (date, time) => {
  if (date && time) {
    let dateTimeFormatted = handleDateTimePayload(date, time);
    let dateSplit = dateTimeFormatted
      ?.split(" ")?.[0]
      ?.split("-")
      ?.reverse()
      ?.join("-");
    let timeSplit = dateTimeFormatted?.split(" ")?.[1];
    let newDateTimeFormatted = new Date([dateSplit, timeSplit]);
    if (isFirstDateGreatOrEqualSeconde(newDateTimeFormatted, new Date())) {
      return newDateTimeFormatted;
    } else {
      return null;
    }
  } else {
    return null;
  }
};

export const handleViewAccordingToPermissions = (action) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const permissions = userInfo?.permissions;
  if (permissions?.find((permmission) => permmission == action)) {
    return true;
  } else {
    return false;
  }
};

export const handleViewAccordingToCaseManagerPermissions = (action) => {
  const caseDetails = store.getState().cases?.caseDetails;
  const permissions = caseDetails?.additional_permissions;
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const caseManager = caseDetails?.case_managers?.find(
    (ele) => ele.id == userInfo.id
  );
  if (
    permissions?.find((permmission) => permmission == action) &&
    caseManager
  ) {
    return true;
  } else {
    return false;
  }
};

export const handleExport = (fileType, fileName, data) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${fileName}.${fileType}`);
  document.body.appendChild(link);
  setTimeout(() => {
    link.click();
    document.body.removeChild(link);
  }, 100);
};

export const displayBlobPdfInPrintWindow = (data) => {
  let blob = new Blob([data], { type: 'application/pdf' });
  let blobURL = URL.createObjectURL(blob);
  let iframe = document.createElement('iframe'); //load content in an iframe to print later
  document.body.appendChild(iframe);
  iframe.style.display = 'none';
  iframe.src = blobURL;
  iframe.onload = function () {
    setTimeout(function () {
      iframe.focus();
      iframe.contentWindow.print();
    }, 1);
  };
}
export const handleAddExternalOptionToautocomplete = (
  permission,
  action,
  text,
  style
) => {
  return (
    handleViewAccordingToPermissions(allPermissions[permission]) && (
      <div
        onMouseDown={(event) => {
          event.preventDefault();
          action();
        }}
        className={`blue cursor-pointer ${style}`}
      >
        + {text}
      </div>
    )
  );
};

export const modifyDayOfDate = (date, day) => {
  let requiredDay, modifiedDate;
  let year = date?.getFullYear().toString();
  let month = (date?.getMonth() + 1).toString();
  if (month.length == 1) {
    month = `0${month}`;
  }
  if (day == "lastDayInMonth") {
    let lastDateInMonth = moment(date).endOf("month");
    requiredDay = new Date(lastDateInMonth)
      .toISOString()
      .split("T")[0]
      ?.split("-")?.[2];
    modifiedDate = `${year}-${month}-${requiredDay}`;
  } else {
    modifiedDate = `${year}-${month}-${day}`;
  }
  return modifiedDate;
};

export const handleCalenderListParams = (
  from,
  to,
  startDay,
  endDay,
  option,
  type
) => {
  let params = {
    from: modifyDayOfDate(from, startDay),
    to: modifyDayOfDate(to, endDay),
    option: option, // my, all
    type: type, //all, invoice, session, task, appointment
  };
  return params;
};

export const removeEleFromList = (list, keyName, keyValue) => {
  const listCopy = [...list];
  if (listCopy.find((ele) => ele[keyName] == keyValue)) {
    listCopy.splice(
      listCopy?.findIndex((ele) => ele[keyName] == keyValue),
      1
    );
    return listCopy;
  } else {
    return list;
  }
};

export const dashboardAllTasksPageIsRendering = () => {
  return Boolean(window.location?.pathname === "/dashboard/allTasks");
};

export const getDayName = (dateStr, locale) => {
  var date = new Date(dateStr);
  return date.toLocaleDateString(locale || "ar", { weekday: "long" });
};

export const handlePrintTable = () => {
  let windPrint = window.open(`${window.location.origin}/printTable`);
  windPrint.focus();
  setTimeout(() => windPrint.print(), 500);
};

export const handleDisplaySingleItemInCaseTab = (tab) => {
  const tabTitle = window.location?.search?.split("&")?.[1];
  let displaySingleItem =
    tabTitle?.split("=")?.[0] == tab && tabTitle?.split("=")?.[1]
      ? true
      : false;
  return displaySingleItem;
};

export const handelBackTolistView = (handelClick) => {
  const lang = store.getState().lang;
  return (
    <div
      onClick={() => {
        History.replace({ search: "" });
        handelClick();
      }}
    >
      <span className="px-3 blue cursor-pointer">{messages[lang].back}</span>
      <img src={leftArrowIcon} alt="" />
    </div>
  );
};

export const concatDateTime = (date, time) => {
  if (date && time) {
    let requiredDate = moment(date).locale("en").format("YYYY-MM-DD");
    let requiredTime = moment(time).locale("en").format("HH:mm:ss");
    return `${requiredDate} ${requiredTime}`;
  } else {
    return moment(date).locale("en").format("YYYY-MM-DD HH:mm:ss");
  }
};

export const displayExternalChartLabels = (list, display, labelKey) => {
  return <div className={` justify-content-around ${display ? display : 'd-flex'}`}>
    {list?.map(item => {
      return <ul className="font-bold font16" style={{ "color": item.color }}>
        <li><span style={{ "color": '#001E55' }}>{labelKey ? item[labelKey] : item.label}</span><span className="darkBlue px-2">{item.count}</span></li> </ul>
    })}
  </div>
}

export const navigateToFreeTrail = () => {
  History.push(`${includedInPathName('contracts', '/contracts/', '/')}التجربة-المجانية`);
  window.scrollTo({ top: 0, behavior: "smooth" });
}

export const displayImportExcelTemplateErrMsgs = (errorObj, model) => {
  const lang = store.getState().lang;
  let displayErrMsgs = ''
  let subSheetName = ''
  if (Object.keys(errorObj)?.length > 0) {

    Object.entries(errorObj).map(([key, value]) => {
      subSheetName = messages[lang].validationKeys[key?.split('-')?.[0]]  || ''
      let rowNum = +(key?.split('-')?.[key?.split('-')?.length -1])
      let rowNumMsg = `*${messages[lang].errors.errorInRowNumber} (${rowNum}) `
      displayErrMsgs += rowNumMsg

      Object.entries(value?.[0]).map(([key, val], i) => {
        if(!((key === 'managers' || key == 'case.start_date') && model === 'cases')){
          let fieldErr = `${messages[lang].validationKeys[key]}: ${val} ${Object.keys(value?.[0])?.length - 1 == i ? '' : ','}`
          displayErrMsgs += fieldErr
        }
      })
    })
  }else{
    store.dispatch(importExcelTemplateResponse({ failImportMsg: messages[lang].errors.errorOccurred}));
  }

  if (displayErrMsgs) {
    store.dispatch(importExcelTemplateResponse({ failImportMsg: displayErrMsgs, subSheet: subSheetName }));
  }
  return displayErrMsgs
}

export const includedInPathName = (path, truePath, falsePath) => {
  return window.location.pathname?.includes(path) ? truePath : falsePath
}

