import { combineReducers } from "redux";
import lang from "./Lang/reducer";
import loader from "./Loader/reducer";
import breadcrumbs from "./Breadcrumbs/reducer";
import snackbar from "./Snackbar/reducer";
import auth from "./Auth/reducer";
import tenants from "./Tenants/reducer";
import cases from "./Cases/reducer";
import lookups from "./Lookups/reducer";
import users from "./Users/reducer";
import tasks from "./Tasks/reducer";
import teamMembers from "./TeamMembers/reducer";
import events from "./Events/reducer";
import parties from "./Parties/reducer";
import contacts from "./Contacts/reducer";
import history from "./History/reducer";
import notifications from "./Notifications/reducer";
import socket from "./Socket/reducer";
import collaborations from "./Collaboration/reducer";
import SelectList from "./ListSelect/reducer";
import dashboard from "./Dashboard/reducer";
import invoices from "./Invoices/reducer";
import firmSettings from "./FirmSettings/reducer";
import FreeTrail from "./FreeTrail/reducer";
import clients from "./Clients/reducer";
import companies from "./Companies/reducer";
import attachments from "./Attachments/reducer";
import sorting from "./Sorting/reducer";
import drawer from "./Drawer/reducer";
import landingPage from "./LandingPage/reducer";
import sessions from "./Sessions/reducer";
import verdicts from "./Verdicts/reducer";
import userRoles from "./Roles/reducer";
import activityLogs from "./ActivityLogs/reducer";
import ContractsAuth from "./ContractsAuth/reducer";
import contracts from "./Contracts/reducer";

export default combineReducers({
  lang,
  loader,
  breadcrumbs,
  snackbar,
  auth,
  tenants,
  cases,
  lookups,
  users,
  tasks,
  teamMembers,
  events,
  parties,
  contacts,
  notifications,
  socket,
  history,
  collaborations,
  SelectList,
  dashboard,
  invoices,
  firmSettings,
  FreeTrail,
  clients,
  companies,
  attachments,
  sorting,
  drawer, 
  landingPage,
  sessions,
  verdicts,
  userRoles,
  activityLogs,
  ContractsAuth,
  contracts,
});
