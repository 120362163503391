import * as types from "./types";

const INITIAL_STATE = { firmsList: {}, emailErrIndexList:[] };

export default function tenants(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.FIRMS_RECIEVE:
      return {
        ...state,
        firmsList: action.payload,
      };
      case types.CHECK_IF_CLIENT_MAIL_IS_TAKEN_RECIEVE:
        return {
          ...state,
          emailErrIndexList: action.payload,
        };
      
    default:
      return state;
  }
}
