import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MoreIcon from "@material-ui/icons/MoreVert";
import { newLogo } from "../../utils/Images";
import ButtonComponent from "../Controls/Button";
import History from "../../routes/History";
import { Link } from "react-scroll";
import { getCookie } from "../../utils/Shared";
import { roles } from "../../utils/Constants";
import DownloadAppPopup from "../../components/DownloadAppPopup";

const useStyles = makeStyles((theme) => ({
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
    "& ul": {
      display: "flex",
      margin: 0,
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  appBar: {
    backgroundColor: "#fff",
    color: "#767676",
    boxShadow:
      "0px 0px 4px rgba(0, 0, 0, 0.04), 0px 8px 16px rgba(0, 0, 0, 0.08)",
  },
  link: {
    fontFamily: "DINNextLTArabic-Medium-4",
    textDecorationLine: "none",
    color: "#767676",
  },
}));

const Navbar = ({ intl: { messages }, replaceEndMenu, newPath }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const mobileMenuId = "primary-search-account-menu-mobile";
  const menuId = "primary-search-account-menu";

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const {
    auth: { isTenant, user },
  } = useSelector((state) => state);
  /////////////////////////////////////////////////////////////////////////////////
  const [dynamicImports, setDynamicImports] = useState(null);

  useEffect(() => {
    // Dynamically import the shared dynamicImports  
    const loadDynamicImports = async () => {
      const { isLocal,
        getSubDomain,
        navigateToFreeTrail
       } = await import('../../utils/Shared.js');
       setDynamicImports({
        isLocal,
        getSubDomain,
        navigateToFreeTrail
      });
    };

    loadDynamicImports();
  }, []);
  ////////////////////////////////////////////////////////////////////////////////
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  // useEffect(() => {
  //   if (getCookie("userInfo") && getCookie('token') && !Object.length(JSON.parse(localStorage.getItem("user")))?.length) {
  //     removeCookie("userInfo");
  //     removeCookie("token");
  //   }
  // }, []);
  useEffect(() => {
    if (document.readyState === "complete") {
      if (
        window?.location?.hash === "#contactUs" &&
        document.getElementById("contactUs")
      ) {
        setTimeout(() => (window.location.href = "#contactUs"), 1000);
      }
    }
  }, [document.readyState, document.getElementById("contactUs")]);

  const startMenu = () => {
    return (
      <div>
        <img alt="logo" src={newLogo} width={'158'} height={'57'} role='menuitem' />
      </div>
    );
  };
  const middleMenuLinks = [
    {
      text: messages.landingPage.navbar.dashboard,
      href: "/",
    },
    // {
    //   text: messages.landingPage.navbar.whoWeAre,
    //   href: "/من-نحن",
    // },
    {
      text: messages.landingPage.navbar.whoIsMustand,
      href: "/ما-هو-مستند",
    },
    {
      text: messages.landingPage.navbar.commonQuestions,
      href: "/الأسئلة-الشائعة",
    },
    // {
    //   text: messages.landingPage.navbar.Blogs,
    //   href: "/المدونة",
    // },
    {
      text: messages.landingPage.navbar.Blogs,
      href: "/المدونة",
    },
    {
      text: messages.landingPage.navbar.contactUs,
      href: "/تواصل-معنا",
    },
    // {
    //   text: messages.landingPage.navbar.clientsReviews,
    //   href: "clientsReviews",
    // },
    // {
    //   text: messages.landingPage.navbar.packages,
    //   href: "packages",
    // },
  ];
  const middleMenu = () => {
    return middleMenuLinks.map((link, i) => (
      <MenuItem>
        <Link
          activeClass="is-current"
          className="nav-link-spy textDecorationNone"
          href={`${newPath ? `/${newPath}/` : link.href}`}
          spy={true}
          smooth={true}
          offset={-70}
          duration={10}
          isDynamic={true}
          ignoreCancelEvents={false}
          key={i}
        >
          <span
          className="darkGrey"
            onClick={() => {
              History.push(`${newPath ? `/${newPath}/` : link.href}`);
            }}
          >
            {link.text}
          </span>
        </Link>
      </MenuItem>
    ));
  };
  const renderDashboardLink = () => {
    if (
      (dynamicImports?.getSubDomain() || localStorage.getItem("subdomain")) &&
      user && user?.role &&
      user?.role?.find((role) => role !== roles?.SUPER_ADMIN)
    ) {
      //user
      return `${dynamicImports?.isLocal() ? "http" : "https"}://${dynamicImports?.getSubDomain() || localStorage.getItem("subdomain")
        }.${process.env.REACT_APP_BASE_URL}/dashboard`;
    } else if (user && !user?.role) {
      // contracts
      return `${dynamicImports?.isLocal() ? "http" : "https"}://${process.env.REACT_APP_BASE_URL}/contracts/list`;
    } else {
      //superAdmin
      return `${dynamicImports?.isLocal() ? "http" : "https"}://${process.env.REACT_APP_BASE_URL
        }/dashboard`;
    }
  };
  const endMenu = () => {
    const path = window.location?.pathname;
    return (
      <div className={`${isMobileMenuOpen ? "" : "d-flex"}`}>
        {path === "/التجربة-المجانية" || path === "/thanks" ? (
          ""
        ) : (
          <MenuItem>
            <ButtonComponent
              content={messages.landingPage.navbar.freeTrial}
              fullWidth
              onClick={dynamicImports?.navigateToFreeTrail}
            />
          </MenuItem>
        )}
        <MenuItem>
          <p
            onClick={() => {
              if (
                !user &&
                !user?.name &&
                (!getCookie("token") || !token) &&
                (!getCookie("userInfo") || !user)
              ) {
                window.location.href = `${`${dynamicImports?.isLocal() ? "http" : "https"}://${process.env.REACT_APP_BASE_URL
                  }/login`}`;
              } else {
                window.location.href = `${getCookie("token") && getCookie("userInfo")
                  ? renderDashboardLink()
                  : `${dynamicImports?.isLocal() ? "http" : "https"}://${process.env.REACT_APP_BASE_URL
                  }/login`
                  }`;
              }
            }}
            className={`${classes.link} color-primary mb-0`}
          >
            {user &&
              user.name &&
              (getCookie("token") || token) &&
              (getCookie("userInfo") || user)
              ? user.name
              : messages.landingPage.navbar.login}
          </p>
        </MenuItem>
      </div>
    );
  };
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={"primary-search-account-menu-mobile"}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {middleMenu()}
      {!replaceEndMenu && endMenu()}
    </Menu>
  );

  return (
    <>
      <div className={classes.grow} >
        <AppBar position="fixed" className={classes.appBar} id='landingpageNav'>
          <DownloadAppPopup />
          <Toolbar className={classes.toolbar} role="menu">
            {startMenu()}
            <div className={classes.sectionDesktop}>{middleMenu()}</div>
            <div className={classes.sectionMobile}>
              <IconButton
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
              {replaceEndMenu && replaceEndMenu}
            </div>
            <div className={classes.sectionDesktop}>{replaceEndMenu ? replaceEndMenu : endMenu()}</div>
          </Toolbar>
        </AppBar>
        {renderMobileMenu}
      </div>
    </>
  );
};
export default injectIntl(Navbar);
