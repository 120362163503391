export const FIRMS_REQUEST = "FIRMS_REQUEST";
export const FIRMS_RECIEVE = "FIRMS_RECIEVE";
// Replace with your request types
export const CREATE_TENANT_REQUEST = "CREATE_TENANT_REQUEST";

export const SET_PASSWORD_REQUEST = "SET_PASSWORD_REQUEST";
export const UPLOAD_LOGO_REQUEST = "UPLOAD_LOGO_REQUEST";
// checkIfSubdomainIsTaken
export const CHECK_IF_SUBDOMAIN_IS_TAKEN_REQUEST =
  "CHECK_IF_SUBDOMAIN_IS_TAKEN_REQUEST";

// checkIfClientMailIsTaken
export const CHECK_IF_CLIENT_MAIL_IS_TAKEN_REQUEST =
"CHECK_IF_CLIENT_MAIL_IS_TAKEN_REQUEST";
export const CHECK_IF_CLIENT_MAIL_IS_TAKEN_RECIEVE =
"CHECK_IF_CLIENT_MAIL_IS_TAKEN_RECIEVE";

export const SWITCH_TO_REAL_ACCOUNT_REQUEST =
"SWITCH_TO_REAL_ACCOUNT_REQUEST";
export const SWITCH_TO_REAL_ACCOUNT_RECIEVE =
"SWITCH_TO_REAL_ACCOUNT_RECIEVE";
